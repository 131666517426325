<template>
  <div class="flex flex-col">
    <div class="flex-1">
      <div class="text-white bg-dark">
        <div class="container flex flex-col pl-5 pr-4 mx-auto mb-8 lg:pr-20 lg:flex-row">
          <div class="lg:w-1/2">
            <h1 class="mt-6 mb-6 font-extrabold lg:mb-10 text-mhead lg:text-head lg:mt-16">Contact us</h1>
            <p>Use the form below to drop us an e-mail. We would love to hear your feedback!</p>
          </div>
          <div class="lg:w-1/2">
            <img v-if="!submitted" src="../assets/ContactUsform.gif" />
            <img v-if="submitted" src="../assets/ContactUsSent.gif" />
          </div>
        </div>
      </div>
      <div v-if="submitted" class="container flex flex-col mx-auto text-center">
        <div class="mb-5 text-head">Thank you!</div>
        <div
          class="mx-10 mb-5 text-lg font-bold"
        >We received your e-mail and will get back to you as soon as we can.</div>
        <red-button class="mt-5" type="router">Back to Home</red-button>
      </div>
      <div v-else class="flex flex-col pl-5 mt-10 lg:flex-row lg:mt-0">
        <form class="container mx-auto mb-20" @submit.prevent="submit">
          <div class="lg:flex">
            <div class="pr-10 lg:w-1/2">
              <label for="name" class="font-bold">Name</label>
              <input
                type="text"
                v-model="name"
                required
                class="border focus:border-red-300 focus:outline-none p3"
              />

              <label for="email" class="font-bold">Your email address</label>
              <p class="text-sm">(Just in case we want to follow up with you.)</p>
              <input
                type="email"
                v-model="email"
                required
                class="border focus:border-red-300 focus:outline-none p3"
              />

              <label for="howhelp" class="font-bold">How can we help you</label>
              <select-drop :options="howWeCanHelp" @selected="selected" class="z-20" />
              <select required v-model="help" style="position:relative;top:-35px;left:20px">
                <option v-for="option in howWeCanHelp" v-text="option.label" :key="option.label"></option>
              </select>
            </div>

            <div class="mr-10 lg:w-1/2">
              <p class="pb-3">
                We're always happy to hear about ways we can help!
                <br />Let us know what other product reviews you'd like to see from us in the future:
              </p>
              <div class="my-3">
                <input
                  type="checkbox"
                  name="chkAnkle"
                  v-model="check.ankle"
                  class="form-checkbox focus:border-red-300"
                />
                <label>Ankle</label>
              </div>
              <div class="my-3">
                <input
                  type="checkbox"
                  name="chkElbow"
                  v-model="check.elbow"
                  class="form-checkbox focus:border-red-300"
                />
                <label>Elbow</label>
              </div>
              <div class="my-3">
                <input
                  type="checkbox"
                  name="chkFinger"
                  v-model="check.finger"
                  class="form-checkbox focus:border-red-300"
                />
                <label>Finger</label>
              </div>
              <div class="my-3">
                <input
                  type="checkbox"
                  name="chkKnee"
                  v-model="check.knee"
                  class="form-checkbox focus:border-red-300"
                />
                <label>Knee</label>
              </div>
              <div class="flex my-3">
                <input
                  type="checkbox"
                  name="chkOther"
                  v-model="check.other"
                  class="form-checkbox focus:border-red-300"
                />
                <label>Other:</label>
                <input
                  v-if="check.other"
                  type="text"
                  v-model="other"
                  ref="other"
                  class="pb-1 -mt-3 border-0 border-b-2 focus:outline-none focus:border-red-300"
                />
              </div>
            </div>
          </div>
          <div class="mr-10">
            <label for="message" class="font-bold">Message</label>
            <textarea
              v-model="message"
              rows="8"
              required="required"
              class="block w-full p-2 border focus:border-red-300 focus:outline-none"
            ></textarea>
            <red-button class="mt-8" type="submit" @click="this.submit()">Submit</red-button>
          </div>
        </form>
      </div>
    </div>
    <footer-md class="px-10 mt-10 text-gray-700 lg:px-0 lg:mt-0" />
  </div>
</template>

<script>
// https://hooks.slack.com/services/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
import Footer from "../components/Footer.vue";
import SelectDrop from "../components/SelectDrop.vue";
import { contactsCollection } from "../firebase";

export default {
  name: "Contact",
  components: {
    "footer-md": Footer,
    "select-drop": SelectDrop,
  },
  methods: {
    selected(item) {
      this.help = item;
    },
    submit() {
      var msg = "Name: " + this.name + "\n";
      msg += "Email: " + this.email + "\n";
      msg += "Subject: " + this.help + "\n";
      msg += "Product Reviews: ";
      if (this.check.ankle) msg += "Ankle ";
      if (this.check.elbow) msg += "Elbow ";
      if (this.check.finger) msg += "Finger ";
      if (this.check.knee) msg += "Knee ";
      if (this.check.other) msg += "Other:  " + this.other;
      msg += "\nMessage: \n" + this.message;
      console.log(msg);
      var json = {
        name: this.name,
        email: this.email,
        subject: this.help,
        product_reviews: this.check,
        other: this.other,
        message: this.message,
        createdAt: new Date(),
      };
      //   console.log(json);
      this.submitted = true;
      contactsCollection
        .add(json)
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    },
  },
  watch: {
    check: {
      handler: function (val) {
        if (val.other) {
          this.$nextTick(() => {
            this.$refs.other.focus();
          });
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      submitted: false,
      name: "",
      email: "",
      message: "",
      other: "",
      help: null,
      check: {
        ankle: false,
        elbow: false,
        finger: false,
        knee: false,
        other: false,
      },
      howWeCanHelp: [
        { label: "Question" },
        { label: "Comment" },
        { label: "Suggestion" },
        { label: "Technical issue with website" },
        { label: "Other" },
      ],
    };
  },
};
</script>

<style lang="scss">
input[type="text"],
input[type="email"] {
  @apply block w-full mr-10 mb-5 p-3;
}

.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 2em;
  width: 2em;
  color: #777;
  background-color: #fff;
  border-color: #e2e8f0;
  border-width: 1px;
  margin-right: 16px;
}

.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='gray' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-color: #fff;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media not print {
  .form-checkbox::-ms-check {
    border-width: 1px;
    color: transparent;
    background: inherit;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-checkbox:focus {
  outline: none;
  //   box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  //   border-color: #63b3ed;
}
</style>
