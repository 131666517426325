<template>
  <div class="relative">
    <span class="inline-block w-full shadow-sm">
      <button
        type="button"
        @click="selectOpen = !selectOpen"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="cursor-pointer relative w-full border border-gray-300 bg-white pl-3 pr-10 py-3 text-left focus:outline-none focus:shadow-outline-red-300 focus:border-red-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
      >
        <div class="flex items-center space-x-3">
          <span class="block truncate" v-text="selected ? selected : 'Select an option' "></span>
        </div>
        <span
          class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none bg-gray-300 w-12"
        >
          <svg
            class="ml-4 h-4 w-4 text-white"
            viewBox="0 0 100 100"
            fill="#fff"
            stroke="currentColor"
            :style="selectOpen ? 'transform:scale(1,1)':'transform:scale(-1,-1)'"
          >
            <path
              d="M 50,5 95,97.5 5,97.5 z"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>
    </span>

    <div class="absolute mt-1 w-full bg-white shadow-lg" v-if="selectOpen">
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="max-h-56 py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5"
      >
        <li
          v-for="(option,idx) in options"
          :key="option.label"
          @click="select(option.label)"
          role="option"
          class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
          :class="idx % 2 ==0 ? 'hover:bg-red-100' : ' bg-shade hover:bg-red-200'"
        >
          <div class="flex items-center space-x-3">
            <span
              class="block truncate"
              :class="option.label == selected ? 'font-semibold' : 'font-normal' "
              v-text="option.label"
            ></span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectOpen: false,
      selected: ""
    };
  },
  props: {
    options: {
      type: Array
    }
  },
  methods: {
    select(item) {
      this.selected = item;
      this.selectOpen = false;
      this.$emit("selected", item);
    }
  }
};
</script>

<style>
</style>